import React from 'react';
import DefaultLayout from '../layouts/DefaultLayout';

export default () => (
  <DefaultLayout
    bodyClass="about"
    pageTitle="Join our team!"
    pageDescription="Join the &yet team."
  >
    <section className="center">
      <div className="content-narrower">
        <h1>We aren’t currently hiring.</h1>
        <p>
          But we are always up for meeting new people and chatting about web
          stuff! Definitely{' '}
          <a href="mailto:contact@andyet.com">send us an email</a> or reach out
          on <a href="https://twitter.com/andyet">Twitter</a>.
        </p>
      </div>
    </section>
  </DefaultLayout>
);
